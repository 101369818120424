/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {

  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/AddUser", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { Id: response.data.Data.Id }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  Registration({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/Registration", item)
        .then(response => {
          //debugger
          resolve(response);
          commit("ADD_ITEM", Object.assign(item, { ID: response.data.ID }));
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/GetAllUsers")
        .then((response) => {
          commit('SET_USERS', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllRoles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/GetAllRoles")
        .then((response) => {
          commit('SET_ROLES', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  addUpdateItem(context, item) {
    return new Promise((resolve, reject) => {
      axios.post("/AddUpdateUser", item)
        .then((response) => {
          // commit('UPDATE_USER', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // ResetPassword(context, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/ResetPassword", {'phone':item.Password,'newPassword':item.PhoneNumber})
  //       .then((response) => {

  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
 ResetPassword(context, item) {
    debugger
    return new Promise((resolve, reject) => {
      axios.post("/ResetPassword?email="+item.email+"&code="+item.code+"&newPassword="+item.password+"&UserTries="+item.UserTries

      )
        .then((response) => {

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/DeleteUser?userId="+id)
        .then((response) => {
          commit('REMOVE_ITEM', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  Confirm(context, id) {
    return new Promise((resolve, reject) => {
      axios.get("/ConfirmUser?userId="+id)
        .then((response) => {
          // commit('REMOVE_ITEM', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
   SendCode(context, email) {

    return new Promise((resolve, reject) => {
      axios.post("/SendVerifyCodeForEmail?email="+ email+'&isResetPassword='+true )
        .then((response) => {

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

}
