<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class=" vx-col sm:w-1/4 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4 mycard">
      <!-- style="max-width:70%  ;" -->
      <vx-card>
        <div class="absolute flex inset-x-0 top-0 mt-6  justify-center">
          <img
            src="@/assets/images/logo.png"
            style="max-width:150px "
          />
        </div>
        <div slot="no-body">
          <div
            class="vx-row no-gutter justify-center items-center"
            style="background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 22px #00000029;
                border-radius:31px 31px 31px 31px;
             opacity: 1;
              height: 500px;"
          >
            <!-- height: 500px; -->

            <div
              class="vx-col hidden lg:block lg:w-1/2"
              style="text-align: center;
                  font: normal normal 200 25px/51px Futura PT;
                     letter-spacing: 0px;
                      color: #FFFFFF;
                      opacity: 1;"
            >
              <p>
                {{ $t("PleaseInsertYour") }}
              </p>
              <p>{{ $t("emailaddress") }}</p>
            </div>

            <div
              class="vx-col sm:w-1/2 md:w-full lg:w-1/2 d-theme-dark-bg  "
              style="border-radius: 31px 31px 31px 31px;
                height: 500px;"
            >
              <div class="p-8 " style="margin-top: 40%;">
                <vs-input
                  type="email"
                  :label-placeholder="$t('Email')"
                  v-model="email"
                  class="w-full mb-8"
                />

                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                  >{{ $t("BackToLogin") }}</vs-button
                >
                <vs-button
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  color="#004477"
                  @click="SendCode()"
                >
                  {{ $t("sendcode") }}</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";
import VxCard from "../../components/vx-card/VxCard.vue";
export default {
  components: { VxCard },
  data() {
    return {
      value1: "",
      email: ""
    };
  },
 
  methods: {
    SendCode() {
      debugger;
      this.$store
        .dispatch("UserList/SendCode", this.email)
        .then(res => {
          var Message = "";
          if (res.data.Message == undefined) {
            Message = res[0].data.Message;
          } else {
            Message = res.data.Message;
          }
          this.$vs.notify({
            color: "warning",
            title: "Send Code",
            text: Message
          });
          if (
            res.status ==200
          ) {
            this.$router.push({
              name: "page-reset-password",
              params: { email: this.email }
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
.mycard .vx-card {
  // padding: 0px !important;
  background: unset;
}
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
  .background {
    color: rgb(255, 9, 9);
  }
  #page-login .stack-top {
    z-index: 9;
    border-radius: 5px;
    background: #202020;
  }
  .head {
    color: white;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
